


//kleuren
$zwart: #282828;
$groen: #4caf50;
$blauw: #15b8cf;
$paars: #a681d6;
$oranje: #e8b46a;
$gebroken-wit: #d2d4d4;
$rood: #d27277;
