html, body{
	width: 100%;
	width: 100%;
	background-color: $zwart;
	font-family: mr-eaves-xl-modern, sans-serif;
	overflow-x: hidden;
}

p{
	font-family: mr-eaves-xl-modern, sans-serif;
}

.english{

}

.dutch{
	display: none;
}

a{
	text-decoration: none;
}

main{
	width: 100%;
	height: 100%;
}

.container{
	max-width: 1280px;
	height: auto;
	margin: 0 auto;
	padding: 0 25px;
}

h1{
	font-size: 2em;
	font-variant: small-caps;
}

span{
	color: $gebroken-wit;
}

strong{
	color: $groen;
}

i{
	color: $blauw;
}

.clear{
	clear: both;
}

#particles-js{
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

header{
	width: 100vw;
	height: 100vh;

	nav{
		width: 100vw;
		height: 50px;
		background-color: $zwart;
		position: fixed;
		z-index: 9;

		h1{
			float: left;
			color: #fff;
			line-height: 50px;
			color: $rood;
			font-size: 2em !important;
			cursor: pointer;
		}

		h3{
			float: right;
			color: #fff;
			font-size: 1.25em;
			margin-right: 30px;
			line-height: 50px;
			color: $blauw;
			font-variant: small-caps;
			cursor: pointer;
		}

		img{
			float: right;
			margin-top: 15px;
			margin-right: 10px;
		}

		#nav-button{
			margin-right: 30px;
		}

		#dutch{
			margin-right: 0 !important;
			cursor: pointer;
		}

		#english{
			cursor: pointer;
		}

	}

	#header-properties{
		float: right;
		margin-top: 30%;

	}

	h1{
		color: $rood;
		font-size: 3em;
		text-transform: uppercase;

	}

	h2{
		color: $oranje;
		line-height: 20px;
		margin: 10px 0 10px 30px;;
		letter-spacing: 1px;

		strong{
			color: $paars;
		}

	}

}

#level1{
	width: 100vw;
	height: 100vh;

	h1{
		color: $blauw;
		margin: 0 0 50px 0;
	}

	#about-me-left{
		width: 45%;
		height: auto;
		float: left;
		margin-right: 10%;

		h3{
			font-size: 1.5em;
		}

		p{
			color: $gebroken-wit;
			margin: 15px 0;
			line-height: 25px;
			word-spacing: 3px;
		}

		h1{
			margin-top: 25px;
		}

	}

	#about-me-right{
		width: 45%;
		height: auto;
		overflow: hidden;

		img{
			width: 100%;
			height: 100%;
			margin: 35% 0 ;
		}

	}

}

#level2{
	width: 100vw;
	height: 100%;

	h1{
		color: $blauw;
	}

	table{
		margin: 25px auto 0 auto;

		td{
			padding: 50px;
		}

	}

	h2{
		color: $oranje;
		width: 100px;
		text-align: center;
		margin: 25px auto 0 auto;
	}

	#html {
		width: 200px;
		height: 200px;
		position: relative;
	}

	#jq{
		width: 200px;
		height: 200px;
		position: relative;
	}

	#php{
		width: 200px;
		height: 200px;
		position: relative;
	}

	#react{
		width: 200px;
		height: 200px;
		position: relative;
	}

	#illu{
		width: 200px;
		height: 200px;
		position: relative;
	}

	#jv{
		width: 200px;
		height: 200px;
		position: relative;
	}

	#h1{
		margin: 0 0 50px 0!important;
	}

}

#level3{
	width: 100vw;
	height: auto;

	h1{
		color: $blauw;
		margin: 0 0 50px 0;
	}

	table{
		margin: 50px 0;

		tr{

		}

		td{
			padding: 15px 20px;
			position: relative;

			img{
				width: 100%;
				height: auto;
				transform-style: preserve-3d;
			}

			.overlay{
				position: absolute;
			  top: 15px;
			  left: 20px;
			  height: calc(100% - 33px);
			  width: calc(100% - 40px);
			  opacity: 0;
			  transition: .7s ease;
				background-color: $zwart;
			}

			.summary{
				position: absolute;
			  top: 20%;
			  left: 5%;
			}

			h3{
				font-size: 1.75em;
				font-variant: small-caps;
				color: #fff;
			}

			h4{
			  font-size: 1.25em;
				text-decoration: none;
				color: #fff;

			}

			a:hover{
				.overlay, .summary{
					opacity: 0.9;
				}
			}

		}

	}

}

#level4{
	width: 100vw;
	height: 100vh;

	h1{
		color: $blauw;
		margin: 0 0 50px 0;
	}

	#contact-links{
		width: 45%;
		height: auto;
		float: left;
		margin-right: 10%;

		h3{
			font-size: 1.5em;
		}

		p{
			color: $gebroken-wit;
			margin: 15px 0;
			line-height: 25px;
			word-spacing: 3px;
		}

		h1{
			margin-top: 25px;
		}

		#mail{
			margin: 50px 0 100px 0;

			img{
				width: auto;
				height: 50px;
				float: left;
			}

			h2 a{
				color: $blauw;
				font-size: 1.25em;
				line-height: 50px;
				margin-left: 25px;
			}

		}

		#phone{

			img{
				width: auto;
				height: 100px;
				float: left;
			}

			h2 a{
				color: $blauw;
				font-size: 1.25em;
				line-height: 100px;
				margin-left: 25px;
			}

		}

	}

	#contact-rechts{
		width: 45%;
		height: auto;
		float: left;

		.contact-blok{
			display: block;
			margin: 20px 0;

			label{
				color: $groen;
				font-size: 1em;
				display: block;
			}

			.var{
				line-height: 23px;
			}

			#human{
				margin-top: 10px;
			}

			.contact-up{
				margin: 0px 0 5px 0;
			}

			.contact-down{
				margin: 5px 0 20px 0;
			}

			input{
				width: 400px;
				height: 20px;
				border: none;
				background-color: #000;
				color: $gebroken-wit;
			}

			textarea{
				max-width: 400px;
				width: 400px;
				max-height: 120px;
				height: 120px;
				border: none;
				background-color: #000;
				color: $gebroken-wit;
			}

		}

		button{
			width: 400px;
			height: 30px;
			background-color: $groen;
			border: none;
			color: $gebroken-wit;
			font-size: 1.25em;
		}

	}

}

footer{
	width: 100vw;
	height: 50px;

	#social{
		display: flex;
		align-items: center;
		justify-content: center;

		img{
			margin-right: 25px;
		}

	}

}
